@import "./../../styles/onmail-constants.scss";

.onmail-pricing-page {
  .background-frame-header{
		height:60vh;
		color: $onmailWhite;
	}
	.background-frame-overlay{
		padding-bottom: 16vh;
	}
	.frame-content{
		margin-top: -16vh;
		margin-bottom: 100px;
		background-color: $onmailWhite;
		border-radius: $onmailGlobalRadius;
    padding: 80px 80px;
	}

  h4.banner-title{
    font-size: 40px;
  }
  .card-padding {
    padding: 20px;
  }
  .inline {
    display: inline-block;
  }
  .toggle-button-wrapper {
    display:flex;
    align-items:center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .toggle-button {
    width:50px;
    height:20px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    img {
      position: absolute;
      transition: all .25s ease-in-out;
      width:20px;
      height:20px;
      right:0px;
    }
  }
  .toggle-button.yearly-plan-selected {
    img {
      left:30px;
    }
  }
  .toggle-button.monthly-plan-selected {
    img {
      left:0px;
    }
  }
  .material-icons {
    font-size: 30px !important;
  }
  .material-icons.small-icon {
    font-size: 20px !important;
    padding-right: 10px;
  }
  #request-invite-link {
    @include transition;
    opacity: .8;
  }
  #request-invite-link:hover {
    @include transition;
    opacity: 1;
    cursor: pointer;
  }
  h6 {
    font-size: .8em;
    letter-spacing: .02em;
  }
  p {
    font-size: .9em;
  }
  #pricing-feature-list {
    p {
      font-size: .75em;
    }
  }

}

@media only screen and (max-width: 800px) {
  .onmail-pricing-page {
    .frame-content {
      padding: 32px 12px;
    }
    .mobile-top-margin {
      margin-top: 20px;
    }
    h4.banner-title{
      font-size: 22px !important;
    }
    .card-padding {
      padding: 30px;
    }
    h5 {
      font-size: 1.5em;
    }
    h6 {
      letter-spacing: .04em;
    }
    h3 {
      font-size: 32px !important;
      margin: 5px 0 8px 0;
    }
    #pricing-feature-list p {
      font-size: .8em;
    }
    .tiny-padding {
      padding: 16px;
    }
    #toggle {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 1400px) {
  .onmail-pricing-page {
    h4.banner-title{
      font-size: 34px;
    }
    h3 {
      font-size: 54px;
    }
  }
}
