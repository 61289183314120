@import "./../../styles/onmail-constants.scss";

.onmail-full-width-image-text {
  h1, h2, h3, h4, p {
    color: $onmailWhite;
  }
  .full-width {
    width: 100vw;
  }
  .full-height {
    min-height: 100vh;
  }
}



@media only screen and (max-width: 800px) {
}
