.onmail-contact-page {
  input, textarea {
    padding: 17px 24px;
    background: #f8f8fa;
    color: #000;
    font-size: 17px;
    font-weight: bold;
    width: 100%;
    border: none;
  }
  input::placeholder, textarea::placeholder {
    color: #9f9f9f;
    opacity: .6;
  }
  label {
    font-size: .9em;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .06em;
  }
  .form-padding {
    padding: 10px;
  }
  .material-icons {
    font-size: 34px !important;
  }
  .material-icons.small-icon {
    font-size: 20px !important;
  }
  .description-padding {
    padding: 30px;
    font-weight: bold;
  }
  p {
    white-space: break-spaces;
  }
}

@media only screen and (max-width: 800px) {
  .onmail-contact-page {
    input, textarea {
      font-size: 100%;
    }
    .description-padding {
      padding: 10px 20px;
      font-weight: normal;
    }
  }
}
