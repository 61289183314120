.onmail-left-right-layout {

}

@media only screen and (max-width: 800px) {
  .onmail-left-right-layout {
    .full-screen-container {
      min-height: 80vh;
    }
  }
}
