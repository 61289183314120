.text-separator{
  //padding:20px;
  .massive-text{
      font-size: 150px;
      font-weight: bold;
      margin:2.5%;
      margin-right:0px;
      margin-left: -.5%;
  }
}
@media only screen and (max-width: 2000px) {
  .text-separator{
    .massive-text{
        font-size: 100px;
    }
  }
}
@media only screen and (max-width: 800px) {
  .text-separator{
    .massive-text{
        font-size: 50px;
    }
  }
}
