/* Spacing */
.tiny-top-margin {
  margin-top:10px;
}
.small-top-margin {
  margin-top:30px;
}
.medium-top-margin {
  margin-top:40px;
}
.large-top-margin {
  margin-top:50px;
}
.extra-large-top-margin {
  margin-top:70px;
}
.tiny-bottom-margin {
  margin-bottom:10px;
}
.small-bottom-margin {
  margin-bottom:30px;
}
.medium-bottom-margin {
  margin-bottom:40px;
}
.large-bottom-margin {
  margin-bottom:50px;
}
.extra-large-bottom-margin {
  margin-bottom:70px;
}


.tiny-padding {
  padding:10px;
}
.small-padding {
  padding:20px;
}
.medium-padding {
  padding:30px;
}
.large-padding {
  padding:40px;
}
.extra-large-padding {
  padding:50px;
}

.section-padding{
  padding-top: 40px;
  padding-bottom: 40px;
}
.no-margin {
  margin: 0 !important;
}
.no-top-margin{
	margin-top: 0px !important;
}
.no-bottom-margin{
	margin-bottom: 0px !important;
}
.no-top-padding{
	padding-top: 0px !important;
}
.no-bottom-padding{
	padding-bottom: 0px !important;
}
.no-left-padding{
	padding-left: 0px !important;
}
.no-right-padding{
	padding-right: 0px !important;
}
.half-width{
  width:50%;
}
.three-quarter-width{
  width:75%;
}
.full-width{
  width:100%;
}
.full-height{
  height:100%;
}
.half-height{
  height:50%;
}
.three-quarter-height{
  height:75%;
}
.max-width-container{
  max-width: 1400px;
  margin: auto;
}
.center-position{
  margin:auto;
}
.small-max-width-container{
	margin:auto;
	max-width: 800px;
	width: 98%;
}
.section-padding{
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.full-screen-container{
  min-height:100vh;
  width: 100vw;
}
@media only screen and (max-width: 800px) {
  .max-width-container{
    max-width: 98%;
  }
  .no-mobile-padding-top{
    padding-top: 0px;
  }
}
@media only screen and (min-width: 800px) {
  .tiny-top-margin {
    margin-top:20px;
  }
  .small-top-margin {
    margin-top:40px;
  }
  .medium-top-margin {
    margin-top:60px;
  }
  .large-top-margin {
    margin-top:80px;
  }
  .extra-large-top-margin {
    margin-top:100px;
  }
  .tiny-bottom-margin {
    margin-bottom:20px;
  }
  .small-bottom-margin {
    margin-bottom:30px;
  }
  .medium-bottom-margin {
    margin-bottom:50px;
  }
  .large-bottom-margin {
    margin-bottom:70px;
  }
  .extra-large-bottom-margin {
    margin-bottom:100px;
  }
  .tiny-padding {
    padding:20px;
  }
  .small-padding {
    padding:30px;
  }
  .medium-padding {
    padding:50px;
  }
  .large-padding {
    padding:70px;
  }
  .extra-large-padding {
    padding:100px;
  }
  .section-padding{
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .max-width-container{
    max-width: 96%;
    margin: auto;
  }
}
@media only screen and (min-width: 1400px) {
  .tiny-top-margin {
    margin-top:20px;
  }
  .small-top-margin {
    margin-top:50px;
  }
  .medium-top-margin {
    margin-top:90px;
  }
  .large-top-margin {
    margin-top:120px;
  }
  .extra-large-top-margin {
    margin-top:200px;
  }
  .tiny-bottom-margin {
    margin-bottom:20px;
  }
  .small-bottom-margin {
    margin-bottom:50px;
  }
  .medium-bottom-margin {
    margin-bottom:90px;
  }
  .large-bottom-margin {
    margin-bottom:120px;
  }
  .extra-large-bottom-margin {
    margin-bottom:200px;
  }
  .tiny-padding {
    padding:20px;
  }
  .small-padding {
    padding:40px;
  }
  .medium-padding {
    padding:80px;
  }
  .large-padding {
    padding:120px;
  }
  .extra-large-padding {
    padding:200px;
  }
  .section-padding{
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .max-width-container{
    max-width: 1400px;
    margin: auto;
  }
  .small-max-width-container{
  	margin:auto;
  	max-width: 1000px;
  	width: 100%;
  }
}
@media only screen and (min-width: 2000px) {
  .max-width-container{
    max-width:1800px;
  }
  .small-max-width-container{
  	margin:auto;
  	max-width: 1200px;
  	width: 100%;
  }
}
