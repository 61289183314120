@import './../../styles/constants.scss';
@import "./../../styles/onmail-constants.scss";

.footer-section {
  padding-top: 100px;
  padding-bottom: 100px;
  border-top: solid 1px #ededed;
}
.inline {
  position: relative;
  display: inline-block;
}
.footer-icon {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  width: 40px;
  height: 40px;
  padding: 10px;
  opacity: 0.6;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}
.footer-icon:hover {
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  opacity: 1;
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
}
.footer-logo {
  width: 140px;
  padding: 0 10px;
}
.footer-link {
  transition: color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  padding: 0px 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
  color: $mediumGrey !important;
  display: inline-block;
}
.footer-link:hover {
  transition: color 0.25s ease-in-out;
  -webkit-transition: color 0.25s ease-in-out;
  -moz-transition: color 0.25s ease-in-out;
  -o-transition: color 0.25s ease-in-out;
  color: $primaryColor !important;
}
.footer-text {
  font-size: 12px;
  letter-spacing: 0.08em;
  opacity: 0.35;
  padding: 10px;
  a {
    transition: all 0.35 ease-in-out;
    color: $mediumGrey !important;
    font-weight: 600;
  }
  a:hover {
    transition: all 0.35 ease-in-out;
    color: $primaryColor !important;
  }
}
@media only screen and (max-width: 800px) {
  .right-text {
    text-align: center;
  }
  .center-on-small {
    align-items: center;
    text-align: center;
  }
}


.onmail-footer {
  .onmail-footer-logo {
    width: 90px;
  }
  .onmail-footer-icon {
    @include transition;
    width: 32px;
    height: 32px;
  }
  a {
    color: $onmailWhite;
    position: relative;
    padding: 8px 0;
  }
  a:before {
    @include transition;
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: $onmailPrimaryColor;
    visibility: hidden;
  }
  a:hover::before {
    @include transition;
    width: 100%;
    visibility: visible;
  }
}

@media only screen and (max-width: 800px) {
  .onmail-footer{
    a:before {
      @include transition;
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      background-color: $onmailPrimaryColor;
      visibility: hidden;
      transform: scaleX(0);
    }
    a:hover::before {
      @include transition;
      visibility: visible;
      transform: scaleX(1);
    }
    .onmail-footer-logo {
      margin: auto;
    }
    .mobile-co-padding {
      padding-top: 32px;
    }
  }
}
