.full-width-parallax-layered-banner{
  position: relative;
  overflow: hidden;
  line-height: 0;
  .parallax-image-background{
    position: relative;
    z-index: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .parallax-image-foreground{
    position: absolute;
    z-index: 3;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .parallax-image-middleground{
    position: absolute;
    z-index: 2;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 800px) {
  .full-width-parallax-layered-banner{
    overflow-x:hidden;
    img{
      width: 150%;
      margin-left: -25%;
    }
  }
  .full-width-parallax-layered-banner.ignore-resize-hack{
    img{
      width: 100%;
      margin-left: 0%;
    }
  }
}
