/* Hero Styling */
@import "./../../styles/constants.scss";

.modal-background{
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
	background-color: rgba(0,0,0,.25);
	transition: opacity .5s ease-out;
	animation-name: darkBackgroundAnimation;
	animation-duration: .5s;
	opacity: 1;
	display: flex;
	align-items: center;
	z-index: 1000000;
}
.modal-background.light-theme{
		background-color: rgba(255,255,255,1);
		animation-name: lightBackgroundAnimation;
}
.modal{
	margin:auto;
	z-index: 1000001;
	max-width: 800px;
	width: 100%;
	max-height: 100%;
	overflow-y:auto;
	transition: opacity .5s ease-out;
	animation-duration: .5s;
	animation-name: foregroundAnimation;
	background-color: #fff;
	padding: 40px;
}
.modal-background.light-theme.animate-out{
	background-color: rgba(255,255,255,0);
}
.modal-background.animate-out{
	background-color: rgba(0,0,0,0);
}

.modal-background.animate-out .modal{
	opacity: 0;
	transform: translateY(20px);
}
.portal-modal-close-icon{
	position: fixed;
	z-index: 10000002;
	top: 40px;
	right: 40px;
	cursor: pointer;
}
@keyframes darkBackgroundAnimation {
    from {	background-color: rgba(0,0,0,0);}
    to {background-color: rgba(0,0,0,.25);}
}

@keyframes lightBackgroundAnimation {
    from {	background-color: rgba(255,255,255,0);}
    to {background-color: rgba(255,255,255,.25);}
}

@keyframes foregroundAnimation {
    from {	opacity: 0; transform: translateY(20px);}
    to {opacity: 1; transform: translateY(0px);}
}

@media only screen and (max-width: 800px) {
  .modal-background.active {
      font-size: 50px;
  }
	.portal-modal-close-icon{
		top: 20px;
		right: 20px;
		img {
			width: 24px;
			height: 24px;
		}
	}
}
