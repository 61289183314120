.onmail-how-to-step {
  .dashed-line {
    width: 2px;
    margin-left:49%;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D8DBDCFF' stroke-width='4' stroke-dasharray='8%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    min-height: 100px;
    height:100%;
    position: relative;
    z-index: 1;
  }
  .middle-icon-wrapper{
    position: absolute;
    bottom: 0px;
    top: 0px;
    left:0px;
    right: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 2;
    .middle-icon-container{
      padding:4px;
      background-color: #fff;
    }
    .material-icons.tiny-icon {
      font-size: 24px !important;
    }
  }
}

@media only screen and (max-width: 800px) {
}
