@import "./../../styles/onmail-constants.scss";

.onmail-menu-container {
  position: fixed;
  z-index: 100;
  height:0px;
  width:0px;
  background-color: rgba(0,0,0,0);
  overflow: hidden;
}

.onmail-menu-container.active {
  top: 0;
  left:0;
  right:0;
  bottom:0;
  height: 100%;
  width: 100%;
}
.onmail-menu-container-transition-class{
  transition: all .5s ease-in-out;
  background-color: rgba(0,0,0,0);
  height: 100%;
  width: 100%;
}
.onmail-menu-container-transition-class.animate-in{
  background-color: rgba(0,0,0,.5);
}
.onmail-menu-container-transition-class.animate-out{
  background-color: rgba(0,0,0,0);
}
.onmail-menu-container.active .onmail-menu {
  transition: all .25s ease-in-out;
  transform: scale(1,1);
  opacity: 1;
  left: 0;
}
.onmail-menu-container-transition-class.animate-out .onmail-menu {
  transform: scale(1.1,1.1);
  opacity: 0;
  left: 0;
}

.onmail-menu {
  height: 100%;
  width: 100%;
  max-width: 400px;
  transition: all .25s ease-in-out;
  transform: scale(1.1,1.1);
  opacity: 0;
  background: $onmailBlack;
  overflow-y: auto;
  position: absolute;
  z-index: 2000;
  box-shadow: 10px 0px 30px rgba(0,0,0,.2);
  .header-link {
    @include transition;
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    padding: 10px 20px;
    margin: 10px 0;
    color: $onmailWhite !important;
  }
  .header-link:hover {
    @include transition;
    color: $onmailPrimaryColor;
  }
  .onmail-close-icon {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
.onmail-menu-container-close {
  padding: 20px;
  cursor: pointer;
  opacity: .6;
  transition: all .33s ease-in-out;
}
.onmail-menu-container-close:hover {
  opacity: 1;
  transition: all .33s ease-in-out;
}

.onmail-brand-stamp{
  padding: 30px 25px;
  display: block;
  z-index: 10000;
  opacity: .5;
  img {
    max-width: 80px;
  }
}
