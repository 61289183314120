/* colors */
$onmailPrimaryColor: #005FED;
$onmailSecondaryColor: #0A3ED8;
$onmailWhite: #fff;
$onmailBlack: #222326;
$onmailExtraDarkGrey: #454b5b;
$onmailDarkGrey: #727982;
$onmailMediumGrey: #8e99a3;
$onmailLightGrey: #d8dbdc;
$onmailExtraLightGrey: #f5f5f5;

/* global values */
$onmailSectionPadding: 160px;
$onmailGlobalRadius: 8px;

/* global transition */
@mixin transition {
  transition: all 0.3s ease-in-out;
  webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
