@import "./../../styles/onmail-constants.scss";

.onmail-vision-page {
  a.hero {
    .material-icons {
      @include transition;
    }
  }
  a.hero:hover {
    .material-icons {
      @include transition;
      transform: translateY(10px);
    }
  }
  .hero-video-overlay {
    width: 100vw;
    height: 100vh;
  }
}

@media only screen and (max-width: 2000px) {
  .onmail-vision-page {

  }
}
@media only screen and (max-width: 1400px) {
  .onmail-vision-page {
    .hero-video-overlay {
      padding-bottom: 10vh;
    }
  }
}
@media only screen and (max-width: 800px) {
  .onmail-vision-page {
    .hero-video-overlay {
      height: 90vh;
    }
  }
}
