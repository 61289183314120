@import "../../styles/constants.scss";

.onmail-connect-page {
  .material-icons .small-icon {
    font-size: 24px !important;
  }
  h3 {
    line-height: 1.15em;
  }
  h5 {
    line-height: 1.5em;
  }
  h6 {
    font-size: .8em;
    letter-spacing: .05em;
  }
  // parallax images for onmail //
  .full-width-parallax-layered-banner {
    img {
      width: 100%;
    }
  }
  #instruction-buttons div {
    transition: all .3s ease-in-out;
    img {
      filter: grayscale(100%);
      transition: all .3s ease-in-out;
    }
    .small-icon {
      opacity: 1;
      transition: all .3s ease-in-out;
    }
  }
  #instruction-buttons div:hover {
    transition: all .3s ease-in-out;
    .material-icons {
      color: $primaryColor !important;
      transition: all .3s ease-in-out;
    }
    img {
      filter: grayscale(0%);
      transition: all .3s ease-in-out;
    }
  }
  #instruction-buttons .active-button {
    transition: all .3s ease-in-out;
    .material-icons {
      color: $primaryColor !important;
      transition: all .3s ease-in-out;
    }
    .small-icon {
      transition: all .3s ease-in-out;
      opacity: 0 !important;
    }
    img {
      filter: grayscale(0%);
      transition: all .3s ease-in-out;
    }
    .button-container {
      border-bottom: solid 6px $primaryColor;
    }
  }
  .border-top {
    border-top: 1px solid rgba($mediumGrey, .3);
  }
}

@media only screen and (max-width: 800px) {
  .center-for-small {
    text-align: center !important;
  }
  .hide-for-small {
    display: none;
  }
  .show-for-small {
    display: block;
  }
  .mobile-top-padding{
    padding-top: 60px;
  }
}
