@import "./../../styles/onmail-constants.scss";

/* Hero Styling */
.onmail-pop-up-frame{
	.onmail-pop-up-frame-header{
		height:100vh;
		color: $onmailWhite;
	}
	.onmail-pop-up-frame-content{
		margin-top: -90vh;
		margin-bottom: 100px;
	}
	.onmail-popup-frame-article {
		background-color: $onmailWhite;
		border-radius: $onmailGlobalRadius;
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.text-max-width-container {
		max-width: 700px;
		margin: auto;
	}
	.quote {
		border-left: 8px solid $onmailPrimaryColor;
		padding-left: 20px;
	}
}

@media only screen and (max-width: 800px) {
	.onmail-pop-up-frame{
		.onmail-pop-up-frame-content{
			padding-top: 40px;
			padding-bottom: 40px;
		}
	}
}
