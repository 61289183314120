/* Spacing */
html,body{
  font-size: 18px;
  margin:0;
}
p{
  font-size: 1em;
  margin:0;
  line-height: 1.5;
}
h6{
  font-size: .9em;
  margin:0;
}
h5{
  font-size: 1.4em;
  margin:0;
}
h4{
  font-size: 1.9em;
  //margin:5px;
}
h3{
  font-size: 2.82em;
  //margin:5px;
}
h2{
  font-size: 3.98em;
  //margin:5px;
}
h1{
  font-size: 6em;
  //margin:5px;
}
@media only screen and (max-width: 800px) {
  p{
    font-size: 1em;
  }
  h6{
    font-size: .8em;
  }
  h5{
    font-size: 1em;
  }
  h4{
    font-size: 1.5em;
  }
  h3{
    font-size: 1.8em;
  }
  h2{
    font-size: 2em;
  }
  h1{
    font-size: 2.25em;
  }
}
