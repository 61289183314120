@import "../../styles/onmail-constants.scss";

.onMail{
  .header-container{
    background-color: rgba(0,0,0,0) !important;
    .show-on-scroll {
      @include transition;
      transform: translateX(50vw);
      width: 0;
      opacity: 0;
    }
  }
  .header-container.active{
    background-color: $onmailBlack !important;
    .show-on-scroll {
      @include transition;
      transform: translateX(0);
      width: auto;
      opacity: 1;
    }
  }
}

.onmail-header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .links-right {
    text-align: right;
  }
  .links-left {
    text-align: left;
  }
  .header-logo-white, .header-logo-black {
    width: 54px;
    height: 54px;
  }
  .header-link {
    font-weight: bold;
    color: $onmailWhite;
    padding: 8px 0;
    cursor: pointer;
  }
  .header-link:before {
    @include transition;
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: $onmailPrimaryColor;
    visibility: hidden;
  }
  .header-link:hover::before {
    @include transition;
    width: 100%;
    visibility: visible;
  }
}

#onmail-signup-overlay{
  height:100%;
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  display: none;
  .modal-close{
    position: absolute;
    right:20px;
    top:20px;
    height:40px;
    width: 40px;
  }
}
#onmail-signup-overlay.active{
  animation-name: slideUp;
  animation-duration: 1s;
  display: block;
  opacity: 1;
}
#onmail-signup-overlay.animateOut{
  animation-name: slideOut;
  animation-duration: 1s;
  display: block;
  opacity: 1;
}

@keyframes slideOut {
  0% {opacity: 1; transform: translate3d(0,0,0);}
  99%   {opacity: 0; transform: translate3d(0,100%,0);}
  100%   {display:none;opacity: 0;}
}

@keyframes slideUp {
  0%   {display:none;opacity: 0;}
  1%   {display:block; opacity: 0; transform: translate3d(0,100%,0);}
  100% {display:block; opacity: 1; transform: translate3d(0,0,0);}
}

@media only screen and (max-width: 800px) {
  .onmail-header-container{
    .onmail-menu-icon {
      padding: 5px 8px;
      cursor: pointer;
    }
  }
}
