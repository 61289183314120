@import "./../../styles/constants.scss";
.hero-text-wrapper {
  margin-top: 100px;
  margin-bottom: 100px;
  h1{
    margin: 0;
  }
  .button.primary-background {
    max-width: 350px;
    background: $primaryColor;
    color: $white;
  }
  .hero-text-max-width{
    width: 78%;
  }
  p.hero-description {
    padding-bottom: 20px;
  }
}


@media only screen and (max-width: 800px) {
  .hero-text-wrapper {
    margin-top: 100px;
    margin-bottom: 0px;
    .hero-text-max-width{
      width: 100%;
    }
    .hero-button {
      width: 100%;
      margin: 10px 0 10px 0 !important;
    }
  }
}
@media only screen and (min-width: 1600px) {
  .hero-text-wrapper {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}
