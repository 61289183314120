@import "./../../styles/onmail-constants.scss";

.onmail-home {
  .material-icons.small-icon {
    font-size: 30px !important;
    padding-left: 10px;
  }
  .material-icons.tiny-icon {
    font-size: 20px !important;
    padding-left: 10px;
  }
  .home-video-overlay {
    padding-bottom: 20vh;
    width: 100vw;
    height: 100vh;
  }
  .overlap-top {
    position: relative;
    margin-top: -30vh;
    z-index: 10;
  }
  img.investor-logo {
		@include transition;
		opacity: 0.6;
		max-width: 300px;
    margin: auto;
	}
	img.investor-logo:hover {
		@include transition;
		opacity: 1;
		cursor: pointer;
	}
  .home-statement {
    a{
      @include transition;
      color: $onmailBlack;
    }
    a:hover{
      @include transition;
      color: $onmailPrimaryColor;
    }
    a:before {
      @include transition;
      content: " ";
      display: block;
      position: absolute;
      width: 0;
      height: 4px;
      bottom: 0;
      left: 0;
      background-color: $onmailPrimaryColor;
      visibility: hidden;
    }
    a:hover::before {
      @include transition;
      width: 100%;
      visibility: visible;
    }
  }

  .onmail-faq {
    background-color: $onmailSecondaryColor;
    color: $onmailWhite;
    .material-icons {
      font-size: 34px !important;
    }
    .material-icons.small-icon {
      font-size: 24px !important;
    }
    .trigger-padding {
      @include transition;
      padding: 20px;
    }
    .trigger-padding:hover {
      @include transition;
      background: $onmailPrimaryColor;
    }
    .description-padding {
      padding: 30px;
      font-weight: bold;
    }
    .answer-box {
      background: $onmailPrimaryColor;
      p {
        white-space: break-spaces;
        color: $onmailWhite;
      }
      a, #faq-inline-link {
        font-weight: bold;
        color: $onmailWhite;
        text-decoration: underline;
      }
    }
    .Collapsible__trigger.is-open i {
      @include transition;
      transform: rotate(135deg);
    }
  }
}

@media only screen and (max-width: 2000px) {
  .onmail-home {

  }
}
@media only screen and (max-width: 1400px) {
  .onmail-home {
    .home-video-overlay {
      padding-bottom: 24vh;
    }
    .overlap-top {
      margin-top: -36vh;
    }
  }
}
@media only screen and (max-width: 800px) {
  .onmail-home {
    .home-video-overlay {
      padding-bottom: 16vh;
      height: 90vh;
    }
    .overlap-top {
      margin-top: -24vh;
    }
    .onmail-faq {
      .description-padding {
        padding: 10px 20px;
        font-weight: normal;
      }
      .answer-box {
        padding: 0 18px;
      }
    }
    .home-statement.section-padding {
      padding-bottom: 20px;
    }
  }
}
