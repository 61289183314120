.full-width-image-text {
  padding: 40px 0px;
  background-size: cover;
  background-position: center;
  .material-icons {
    font-size: 30px !important;
  }
  .left-full-width-padding {
    padding-left: 120px;
  }
  .right-full-width-padding {
    padding-right: 120px;
  }
  .full-width-parallax-layered-banner{
    position: relative;
    overflow: hidden;
    line-height: 0;
    .parallax-image-background{
      position: relative;
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .parallax-image-foreground{
      position: absolute;
      z-index: 3;
      top: 0;
      left:0;
      right:0;
      bottom:0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .parallax-image-middleground{
      position: absolute;
      z-index: 2;
      top: 0;
      left:0;
      right:0;
      bottom:0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .parallax-image-background, .parallax-image-middleground, .parallax-image-foreground{
      max-width: 800px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .full-width-image-text {
    padding-top: 20px 0px;
    background-size: cover;

    .full-width-parallax-layered-banner{
      overflow-x:hidden;
      img{
        width: 100% !important;
        margin-left: 0 !important;
      }
    }
    .left-full-width-padding {
      padding-left: 20px;
    }
    .right-full-width-padding {
      padding-right: 20px;
    }
  }
}
