@import "./../../styles/onmail-constants.scss";

.onmail-experience-page {
  .animated-header-phrase{
    @include transition;
    opacity: 0;
    transform: translateY(5%);
  }
  .animated-header-phrase.active{
    opacity: 1;
    transform: translateY(0);
  }
  .hero-video-overlay {
    width: 100vw;
    height: 100vh;
  }
}

@media only screen and (max-width: 800px) {
  .onmail-experience-page {
    .hero-video-overlay {
      height: 90vh;
    }
  }
}
