@import 'onmail-constants.scss';

$onmail-colors: (
  onmail-primary: $onmailPrimaryColor,
  onmail-secondary: $onmailSecondaryColor,
  onmail-white: $onmailWhite,
  onmail-black: $onmailBlack,
  onmail-extra-dark-grey: $onmailExtraDarkGrey,
  onmail-dark-grey: $onmailDarkGrey,
  onmail-medium-grey: $onmailMediumGrey,
  onmail-light-grey: $onmailLightGrey,
  onmail-extra-light-grey: $onmailExtraLightGrey
);
@each $name, $value in $onmail-colors {
  .#{$name} {
    color: $value;
  }
}
@mixin background-color-modifiers {
  @each $name, $value in $onmail-colors {
    &-#{$name} {
      background-color: $value;
    }
  }
}
.background {
  @include background-color-modifiers;
}

/* gradient */
@mixin onmail-gradient {
  background: -webkit-linear-gradient($onmailPrimaryColor, $onmailSecondaryColor);
  background: -moz-linear-gradient($onmailPrimaryColor, $onmailSecondaryColor);
  background: -o-linear-gradient($onmailPrimaryColor, $onmailSecondaryColor);
  background: linear-gradient($onmailPrimaryColor, $onmailSecondaryColor);
}

/* text */
$font-sizes: (
  h1: 100px,
  h2: 60px,
  h3: 44px,
  h4: 32px,
  h5: 20px,
  h6: 74px,
  p: 20px
);
@each $name, $size in $font-sizes {
  #{$name} {
    font-size: $size;
    line-height: 1.45em;
    white-space: initial;
    margin: 0;
  }
}
h1, h2 {
  line-height: 1.2em;
}
p {
  line-height: 1.63em !important;
}
.white-text {
  color: $onmailWhite !important;
}
.regular-text {
  font-weight: 300;
}

/* padding */
$padding: (
  tiny: 12px,
  small: 32px,
  medium: 52px,
  large: 80px,
  extra-large: 160px
);
@mixin padding-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      padding: $size;
    }
  }
}
.padding {
  @include padding-modifiers;
}
@mixin padding-top-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      padding-top: $size;
    }
  }
}
.padding-top {
  @include padding-top-modifiers;
}
@mixin padding-right-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      padding-right: $size;
    }
  }
}
.padding-right {
  @include padding-right-modifiers;
}
@mixin padding-bottom-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      padding-bottom: $size;
    }
  }
}
.padding-bottom {
  @include padding-bottom-modifiers;
}
@mixin padding-left-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      padding-left: $size;
    }
  }
}
.padding-left {
  @include padding-left-modifiers;
}
.section-padding {
  padding-top: $onmailSectionPadding;
  padding-bottom: $onmailSectionPadding;
}

/* margins */
$margin: (
  tiny: 10px,
  small: 32px,
  medium: 52px,
  large: 80px,
  extra-large: 160px
);
@mixin margin-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      margin: $size;
    }
  }
}
.margin {
  @include margin-modifiers;
}
@mixin margin-top-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      margin-top: $size;
    }
  }
}
.margin-top {
  @include margin-top-modifiers;
}
@mixin margin-right-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      margin-right: $size;
    }
  }
}
.margin-right {
  @include margin-right-modifiers;
}
@mixin margin-bottom-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      margin-bottom: $size;
    }
  }
}
.margin-bottom {
  @include margin-bottom-modifiers;
}
@mixin margin-left-modifiers {
  @each $name, $size in $padding {
    &-#{$name} {
      margin-left: $size;
    }
  }
}
.margin-left {
  @include margin-left-modifiers;
}

/* global styles */
.button-onmail {
  @include onmail-gradient;
  color: $onmailWhite !important;
  padding: 16px 44px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}
.button-onmail-large {
  @include onmail-gradient;
  color: $onmailWhite !important;
  padding: 32px 72px;
  min-width: 300px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}
.button-onmail-white {
  background: $onmailWhite;
  color: $onmailSecondaryColor !important;
  padding: 16px 52px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
}
.overlay {
  background: rgba(0,0,0,.2);
}
i {
  @include transition;
  transform: rotate(0deg);
}
img, video {
  display: block;
  line-height: 0;
}
a {
  .material-icons.animate-right {
    @include transition;
  }
}
a:hover {
  .material-icons.animate-right {
    @include transition;
    transform: translateX(4px);
  }
}

/* body + html */
html, body {
  font-family: 'Helvetice Nueue', sans-serif;
  color: $onmailBlack;
}
body {
  -webkit-overflow-scrolling: touch;
  font-size: 20px;
  overflow-x: hidden;
}
html {
  box-sizing: border-box;
  font-weight: 400;
}
*, *:before, *:after {
  box-sizing: inherit;
}

/* show and hide for screen sizes */
@media only screen and (max-width: 800px) {
  .onMail {
    .center-for-small {
      text-align: center !important;
      justify-content: center !important;
    }
    .hide-for-small {
      display: none;
    }
    .show-for-small {
      display: block;
    }
  }
}

/* media queries */
@media only screen and (max-width: 2000px) {
}
@media only screen and (min-width: 800px) and (max-width: 1400px) {

  $font-sizes: (
    h1: 80px,
    h2: 44px,
    h3: 32px,
    h4: 24px,
    h5: 18px,
    h6: 60px,
    p: 18px
  );
  @each $name, $size in $font-sizes {
    #{$name} {
      font-size: $size;
      line-height: 1.3em;
      white-space: initial;
      margin: 0;
    }
  }
    $padding: (
      tiny: 10px,
      small: 20px,
      medium: 41px,
      large: 70px,
      extra-large: 120px
    );
    @mixin padding-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding: $size;
        }
      }
    }
    .padding {
      @include padding-modifiers;
    }
    @mixin padding-top-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-top: $size;
        }
      }
    }
    .padding-top {
      @include padding-top-modifiers;
    }
    @mixin padding-right-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-right: $size;
        }
      }
    }
    .padding-right {
      @include padding-right-modifiers;
    }
    @mixin padding-bottom-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-bottom: $size;
        }
      }
    }
    .padding-bottom {
      @include padding-bottom-modifiers;
    }
    @mixin padding-left-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-left: $size;
        }
      }
    }
    .padding-left {
      @include padding-left-modifiers;
    }
    $onmailSectionPaddingTablet: 80px;
    .section-padding {
      padding-top: $onmailSectionPaddingTablet;
      padding-bottom: $onmailSectionPaddingTablet;
    }
    $margin: (
      tiny: 6px,
      small: 20px,
      medium: 40px,
      large: 60px,
      extra-large: 100px
    );
    @mixin margin-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin: $size;
        }
      }
    }
    .margin {
      @include margin-modifiers;
    }
    @mixin margin-top-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-top: $size;
        }
      }
    }
    .margin-top {
      @include margin-top-modifiers;
    }
    @mixin margin-right-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-right: $size;
        }
      }
    }
    .margin-right {
      @include margin-right-modifiers;
    }
    @mixin margin-bottom-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-bottom: $size;
        }
      }
    }
    .margin-bottom {
      @include margin-bottom-modifiers;
    }
    @mixin margin-left-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-left: $size;
        }
      }
    }
    .margin-left {
      @include margin-left-modifiers;
    }

}
@media only screen and (max-width: 800px) {

    body {
      font-size: 16px;
    }
    $font-sizes: (
      h1: 36px,
      h2: 28px,
      h3: 22px,
      h4: 18px,
      h5: 16px,
      h6: 32px,
      p: 16px
    );
    @each $name, $size in $font-sizes {
      #{$name} {
        font-size: $size;
        line-height: 1.5em;
        white-space: initial;
        margin: 0;
      }
    }
    h1, h2 {
      line-height: 1.3em;
    }
    $padding: (
      tiny: 8px,
      small: 16px,
      medium: 28px,
      large: 60px,
      extra-large: 100px
    );
    @mixin padding-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding: $size;
        }
      }
    }
    .padding {
      @include padding-modifiers;
    }
    @mixin padding-top-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-top: $size;
        }
      }
    }
    .padding-top {
      @include padding-top-modifiers;
    }
    @mixin padding-right-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-right: $size;
        }
      }
    }
    .padding-right {
      @include padding-right-modifiers;
    }
    @mixin padding-bottom-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-bottom: $size;
        }
      }
    }
    .padding-bottom {
      @include padding-bottom-modifiers;
    }
    @mixin padding-left-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          padding-left: $size;
        }
      }
    }
    .padding-left {
      @include padding-left-modifiers;
    }
    $onmailSectionPaddingMobile: 80px;
    .section-padding {
      padding-top: $onmailSectionPaddingMobile;
      padding-bottom: $onmailSectionPaddingMobile;
    }

    $margin: (
      tiny: 6px,
      small: 12px,
      medium: 40px,
      large: 60px,
      extra-large: 100px
    );
    @mixin margin-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin: $size;
        }
      }
    }
    .margin {
      @include margin-modifiers;
    }
    @mixin margin-top-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-top: $size;
        }
      }
    }
    .margin-top {
      @include margin-top-modifiers;
    }
    @mixin margin-right-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-right: $size;
        }
      }
    }
    .margin-right {
      @include margin-right-modifiers;
    }
    @mixin margin-bottom-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-bottom: $size;
        }
      }
    }
    .margin-bottom {
      @include margin-bottom-modifiers;
    }
    @mixin margin-left-modifiers {
      @each $name, $size in $padding {
        &-#{$name} {
          margin-left: $size;
        }
      }
    }
    .margin-left {
      @include margin-left-modifiers;
    }
    .button-onmail {
      padding: 12px 32px;
    }
    .button-onmail-large {
      padding: 24px 60px;
    }
    .button-onmail-white {
      padding: 12px 32px;
    }
    .truste {
      margin: auto;
    }

}
