@import './../../styles/onmail-constants.scss';

/* Hero Styling */
.video-hero{
	position: relative;
	line-height: 0;
	.video-hero-foreground{
		position: relative;
		z-index: 3;
	}
	.video-hero-tint{
		position: absolute;
		top:0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color:#000;
		z-index: 2;
	}
	.video-hero-background{
		position: absolute;
		top:0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 1;
		z-index: 1;
	}
	.video-hero-background.ready{
		opacity: 1;
	}
	h1, h2, h3, h4, h5, p {
		color: #fff;
	}
}

@media only screen and (max-width: 800px) {
}
