.fadeInUp-enter {
  opacity: 0.01;
  transform: translateY(50px);
}

.fadeInUp-enter.fadeInUp-enter-active {
  transition: opacity 250ms ease-in;
  opacity: 1;
}

.fadeInUp-appear {
  opacity: 0.01;
}
.fadeInUp-appear.fadeInUp-appear-active {
  opacity: 1; transition: opacity 250ms ease-in;
}

.fadeInUp-leave {
  opacity: 1;
}

.fadeInUp-leave.fadeInUp-leave-active {
  opacity: 0.01;
  transition: opacity 100ms ease-in;
}
