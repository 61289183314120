@import 'constants.scss';
@import 'colors.scss';
@import 'spacing.scss';
@import 'grid.scss';
@import 'fontSizing.scss';
@import 'animations.scss';
@import 'onmail-constants.scss';

html,
body {
  font-family: 'Helvetice Nueue', sans-serif;
}

body {
  -webkit-overflow-scrolling: touch;
}
html {
  box-sizing: border-box;
  font-family: $primaryFont;
  font-weight: 400;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  white-space: initial;
  margin: 0.5em 0;
  line-height: 1.3em;
}
p {
  white-space: initial;
}
.uppercase {
  text-transform: uppercase;
}
a {
  cursor: pointer;
}
html,
body,
#root,
.wrapper {
  display: block;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  background-color: #fff;
}
main {
  width: 100%;
  overflow: scroll;
  align-self: flex-start;
  position: absolute;
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-top: 80px;
  top: 0;
}
.subheading {
  opacity: 0.4;
}
.background-image-fill {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.background-image-fit {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.flex {
  display: flex;
}
.middle {
  align-items: center;
}
.bottom {
  align-items: flex-end;
}
.section-margin {
  margin-top: 5%;
}
.button {
  display: inline-block;
  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.button:hover {
  transform: scale(0.97, 0.97);
}

.button-brightness:hover {
  filter: brightness(0.85);
  transition: all 0.25s ease-in-out;
}
/* terms and conditions styling */
.terms-wrapper {
  h3 {
    margin: 40px 0;
  }
  h4 {
    font-size: 1em;
    color: $black;
    text-transform: uppercase;
    margin: 40px 0 20px 0;
  }
  h6 {
    font-size: 0.9em;
    color: $mediumGrey;
    text-transform: uppercase;
    margin: 20px 0;
  }
  p {
    color: $mediumGrey;
  }
}
/* privacy styling */
.privacy-wrapper {
  h2,
  h3 {
    margin: 40px 0 30px 0;
    font-size: 2.82em;
    text-transform: none;
  }
  h4 {
    font-size: 1em;
    color: $black;
    text-transform: uppercase;
    margin: 40px 0 20px 0;
  }
  h6 {
    font-size: 0.9em;
    color: $mediumGrey;
    text-transform: uppercase;
    margin: 20px 0;
  }
  p {
    color: $mediumGrey;
    margin-top: 10px;
  }
  ul {
    color: $mediumGrey;
    line-height: 1.5em;
    padding-inline-start: 30px;
    li {
      margin-bottom: 10px;
    }
  }
  ol {
    color: $mediumGrey;
    line-height: 1.5em;
    padding-inline-start: 30px;
    li {
      margin-bottom: 10px;
    }
  }
  .subnav-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
  }
  .link {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    display: inline-flex;
    width: 180px;
    padding: 15px;
    margin-right: 10px;
    text-align: center;
    background: $lightGrey;
    color: $darkGrey;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.025em;
    border-radius: 25px;
    justify-content: center;
  }
  .link:hover {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    color: $primaryColor !important;
    cursor: pointer;
  }
  .link.active {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    background: $primaryColor !important;
    color: $white !important;
    cursor: pointer;
  }
}
#main-window {
  height: 100%;
}
.centered-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.left-text {
  text-align: left;
}
.background-image-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.card {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  background: $white;
}
.shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
}
.background-hover-zoom {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease-in-out;
}
.background-hover-zoom:hover {
  background-size: 105%;
}
.circle {
  border-radius: 50%;
}
a,
a:active,
a:hover,
a:link {
  color: $primaryColor;
  text-decoration: none;
}
input {
  border: 0px;
  overflow: hidden;
}
input:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.embed-container .react-player {
  height: auto !important;
  width: 100% !important;
}
.relative {
  position: relative;
}
.hero-text {
  font-size: 120px;
}
.bold-text {
  font-weight: bold !important;
}
.animated {
  transition: all 0.5s ease-out;
}
.offset-grid {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20%;
}
.offset-grid > div:nth-child(odd) {
  margin-bottom: 20%;
  margin-top: -20%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container input {
  background-color: #f9f9f9;
  padding: 10px;
}
.hover-zoom {
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.hover-zoom:hover {
  transform: scale(0.97, 0.97);
}
.material-icons {
  font-size: 50px !important;
}





/* Mobile Styles */
.show-for-small {
  display: none;
}
.hide-for-small {
  display: block;
}
.center-for-small {
  text-align: inherit;
}
/* Hero's for mobile */
.mobile-hero,
.mobile-hero-image,
.mobile-hero-overlay-image {
  display: none;
  position: relative;
}
#page-content {
  opacity: 0;
}
#page-content.active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  animation: pageIntro 0.5s;
}
@keyframes pageIntro {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.privacy-table {
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-collapse: collapse;
}
.privacy-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.privacy-table tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.privacy-table-header td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  p {
    font-weight: bold;
    color: black;
  }
}
select {
  padding: 20px;
  padding-left: 20px;
  padding-right: 50px;
  box-sizing: border-box;
  margin: 0;
  border: 0px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 20px top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  min-width: 200px;
}
.stretch-row {
  display: flex;
  align-content: stretch;
  div {
    flex: 1;
  }
}
.blog-editor{
  h6{
    font-size: 20px;
  }
}
@media only screen and (min-width: 2000px) {

}
@media only screen and (max-width: 2000px) {
  .hero-text {
    font-size: 90px;
  }
  .onmail-faq.material-icons {
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 800px) {
  .center-for-small {
    text-align: center !important;
  }
  .no-mobile-top-padding {
    padding-top: 0;
  }
  .mobile-top-padding {
    padding-top: 60px;
  }
  .hide-for-small {
    display: none;
  }
  .show-for-small {
    display: block;
  }
  .first-for-small {
    order: -1;
  }
  .last-for-small {
    order: 10;
  }
  .mobile-hero {
    display: block;
    width: 100vw;
    height: 100vh;
  }
  .mobile-hero-image {
    display: block;
    width: 100vw;
    height: 70vh;
    .mobile-hero-overlay {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
      min-height: 50vh;
      display: flex;
      align-items: left;
      text-align: left;
      h1 {
        font-size: 58px;
      }
    }
    .mobile-hero-overlay-color {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      opacity: 0.6;
    }
    .mobile-hero-background-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      background-size: cover;
      background-position: center;
    }
  }
  .mobile-hero-overlay-image {
    display: block;
    position: relative;
    z-index: 3;
    margin-top: -10vh;
    justify-content: flex-end;
    img {
      width: 45vw;
    }
  }
  .mobile-hero-overlay-image.right-text {
    text-align: right !important;
  }

  .simple-mobile-page {
    .large-padding {
      padding: 20px;
    }
  }
  .material-icons {
    font-size: 36px !important;
  }
  .hero-text {
    font-size: 40px;
  }
  .offset-grid > div:nth-child(odd) {
    margin-bottom: 5%;
    margin-top: 0%;
  }
  .standard-page-container {
    //header height
  }
  /* terms and conditions styling */
  .terms-wrapper {
    h3 {
      margin: 20px 0;
    }
    h4 {
      font-size: 1em;
      color: $black;
      text-transform: uppercase;
      margin: 20px 0 12px 0;
    }
    p {
      color: $mediumGrey;
    }
  }
  /* privacy styling */
  .privacy-wrapper {
    h2,
    h3 {
      margin: 20px 0 15px 0;
      font-size: 1.82em;
      text-transform: none;
    }
    h4 {
      font-size: 1em;
      color: $black;
      text-transform: uppercase;
      margin: 20px 0 12px 0;
    }
    h6 {
      font-size: 0.9em;
      color: $mediumGrey;
      text-transform: uppercase;
      margin: 20px 0;
    }
  }
}
