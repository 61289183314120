@import "./../../styles/onmail-constants.scss";

.onmail-get-started-page {
  a .card {
    @include transition;
    transform: scale(1);
  }
  a .card:hover {
    @include transition;
    transform: scale(0.98);
  }
  .material-icons.small-icon {
    font-size: 32px !important;
  }
}
