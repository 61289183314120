$primaryFont: "Helvetica Neue", Helvetica, Arial, sans-serif;
$secondaryFont:'Source Serif Pro', serif;
$primaryColor: #0062e7;
$darkGrey: #222;
$mediumGrey: #8C8C8C;
$lightGrey: #f9f9f9;
$black: #000;
$white: #fff;
$green: #4cd964;
$red: #ff2d55;
$orange: #FD9326;
$purple: #5C3BFA;
$globalRadius: 4px;
