.onmail-our-story-page {
  img.investor-logo {
    opacity: 0.6;
  }
  .max-width-container{
    max-width: 98%;
  }
  .background-custom {
    background-color: #93919e;
  }
}

@media only screen and (max-width: 2000px) {

}
@media only screen and (min-width: 800px) and (max-width: 1400px) {

}
@media only screen and (max-width: 800px) {
  .onmail-our-story-page {
    .background-image-fill {
      background-size: contain;
      background-position: center top;
      
    }
    .mobile-top-margin {
      position: relative;
      margin-top: 50vh;
    }
  }
}
