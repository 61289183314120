@import "./../../styles/onmail-constants.scss";

.onmail-framed-full-width-image-text {
  position: relative;
  height:94vh;
  h1, h2, h3, h4, h5, p {
    color: $onmailWhite;
  }
  .small-icon {
    font-size: 36px !important;
  }
  .onmail-framed-full-width-image-text-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .onmail-framed-full-width-image-text-background{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    transform: scale(.95);
    transition: all .5s ease-in-out;
  }
}

.onmail-framed-full-width-image-text.active .onmail-framed-full-width-image-text-background{
  transform: scale(1);
}


@media only screen and (max-width: 800px) {
  .onmail-framed-full-width-image-text {
    height: 90vh;
    .small-icon {
      font-size: 24px !important;
      margin-top: 2px;
    }
  }
}
